<template>
    <div class="m-3">
        <v-row class="m-3" v-if="show_site_filter == 1">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom">
                <label class="mb-2">{{titleChoice}} : </label>
                <div class="data-picker" style="width:100%">
                    <ui-select
                        :placeholder="titleChoice"
                        :options="agencesOption"
                        @change="loadConfig(agenceSelect)"
                        v-model="agenceSelect"
                    ></ui-select>
                </div>
            </div>
        </v-row>
        <!-- <v-container id="dashboard" fluid tag="section" > -->
            <v-row class="m-3">
                <v-col cols="12" sm="6" lg="4">
                    <base-material-stats-card color="blue" icon="mdi-poll"
                    title="Soldes " :value="solde" sub-icon="mdi-tag"
                    sub-text="Solde Actuel de l'agence"
                    />
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                    <base-material-stats-card color="primary" icon="mdi-poll"
                    title="Versement" :value="versement" sub-icon="mdi-tag"
                    sub-text="Total versements sur le mois"
                    />
                </v-col>
                <v-col cols="12" sm="6" lg="4">
                    <base-material-stats-card color="red" icon="mdi-poll"
                    title="Dépenses" :value="depense" sub-icon="mdi-tag"
                    sub-text="Total des dépenses sur le mois"
                    />
                </v-col>
            </v-row>
        <!-- </v-container> -->

        <div>
            <div class="row d-flex justify-content-between">
                <div class="col-7">
                    <el-button v-for="item in button_add_config" :key="item.id" type="info" plain @click="loadConfigChamp(item)">{{item.libelle}}</el-button>
                </div> 
                <div class="col-4 d-flex">
                    <div class="data-picker mr-2 ml-4" style="width:100%">
                        <ui-select
                            :placeholder="titleChoice"
                            :options="ResdatesOption"
                            v-model="ResdatesSelect"
                        ></ui-select>
                    </div>
                    <el-button class='ml-auto mr-2' type="primary" icon="el-icon-refresh-left" circle @click="resetCompte(ResdatesSelect)"></el-button>
                </div>
            </div>

            <RegularTableHeaderAdd v-bind:title="title" v-bind:headers="headers" v-bind:items="items" />
        </div>


        <BaseModal v-bind:dialog="dialog" v-bind:data_champ="data_champ" v-bind:Modal_title="Modal_title" v-bind:type="type" v-bind:loadConfig="loadConfig" v-bind:agenceSelect="agenceSelect"/>

    </div>

</template>

<script>

    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';
    import RegularTableHeaderAdd from '@/components/Tables/RegularTableHeaderAdd.vue';
    import BaseModal from '@/components/Modal/baseCardModalComponent.vue';

    export default {

        components: {
            RegularTableHeaderAdd,
            BaseModal,
        },

        data() {

            return{

                title: 'Historique des opérations',
                titleChoice: "Choisir l'agence",
                dialog: false,
                button_add_config: [],
                agencesOption: [],
                show_site_filter: 0,
                agenceSelect: [],
                ResdatesOption:[],
                ResdatesSelect:[],
                data_champ: [],
                Modal_title: '',
                type: null,
                solde: null,
                versement: null,
                depense: null,

                headers: [
                    {
                    sortable: true,
                    text: 'Journée concernée',
                    value: 'date',
                    },
                    {
                    sortable: true,
                    text: 'Montant',
                    value: 'montant',
                    },
                    {
                    sortable: true,
                    text: 'Auteur',
                    value: 'auteur',
                    },
                    {
                    sortable: true,
                    text: 'Type',
                    value: 'type',
                    },
                    {
                    sortable: true,
                    text: "Date d'enregistrement",
                    value: 'created_at',
                    }
                    
                ],
                items: [],
            }
        },

        created() {
            this.loadFilter();
        },

        methods: {

            async loadFilter()
            {
                NProgress.start();

                try {
                  
                    const response = await axios.get('xperf/report/filter',);
                    var res_agences = response.data.agences;

                    var agences = [];

                    var array_agence = [];

                    res_agences.forEach(element => {
                    
                        if (!agences.includes(element.code)) {
                            agences.push(element.code);
                            array_agence.push({
                                label: element.nom,
                                value: element.code
                            });
                        }
                    });

                    if(array_agence.length >1){
                       this.show_site_filter= 1;
                    }
                    this.agencesOption = array_agence;
                    this.agenceSelect = this.agencesOption[0];


                } catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }
                
                this.loadConfig(this.agenceSelect);

                NProgress.done();

            },
            async loadFilterRes(agence)
            {
                NProgress.start();

                let crm = {
                    code_agence : agence.value,
                };

                try {
                  
                    const response = await axios.post('xperf/backupSolde/index', crm);
                    var res_dates = response.data.filterDate;

                    var array_date = [];

                    res_dates.forEach(element => {
                        array_date.push({
                            label: element.date,
                            value: element.date
                        });
                    });

                    this.ResdatesOption = array_date;
                    this.ResdatesSelect = this.ResdatesOption[0];


                } catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();

            },
            async loadConfig(agence) {

                NProgress.start();

                let crm = {
                    'agence' : agence.value,
                }

                console.log(crm);

                try {

                    const response = await axios.post('xperf/index', crm);
                    
                    this.button_add_config = response.data.configType;
                    this.solde = response.data.solde;
                    this.versement = response.data.versement;
                    this.depense = response.data.depense;

                    this.dialog = false;

                    this.loadFilterRes(agence);
                    this.loadDataTab(crm);

                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec de recuperation des informations "
                    });
                }

                NProgress.done();

            },
            
            async loadConfigChamp(config) {

                NProgress.start();

                try {

                    let crm = {
                        id: config.id
                    };

                    const response = await axios.post('xperf/getConfigChampEtat', crm);
                    var res = response.data.configChamp;
                    
                    this.data_champ = res;
                    this.type = config.id;
                    this.Modal_title = config.libelle;
                    this.dialog = true;


                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec de recuperation des informations "
                    });
                }

                NProgress.done();

            },

            async loadDataTab(crm) {

                NProgress.start();

                this.items = [];
                
                try {

                    const response = await axios.post('xperf/getShortOp', crm);
                    var res = response.data.data;

                    res.forEach(element => {

                      let crm = {
                        created_at: element.created_at,
                        date : element.date,
                        montant: element.montant,
                        auteur: element.auteur,
                        type: element.libelle,
                      };
                    
                      this.items.push(crm);
                    });


                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec de recuperation des informations"
                    });
                }

                NProgress.done();

            },

            async resetCompte(date) {

                NProgress.start();

                let crm = {
                    code_agence : this.agenceSelect.value,
                    date : date.value,
                };

                try {
                  
                    const response = await axios.post('xperf/backupSolde/restore', crm);

                    this.loadConfig(this.agenceSelect);
                    this.$notify.success({
                        title: 'Restoration',
                        message: 'Effectuer avec succes',
                        offset: 100
                    });
                

                } catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();               
            }

        },
    }
</script>