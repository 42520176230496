<template>
    <v-dialog v-model="dialog" width="700">
        <v-card-text>
        <v-row>
        <v-col cols="12">
            <base-material-card>
            <template v-slot:heading>
                <div class="display-2 font-weight-light">
                    {{Modal_title}}
                </div>
            </template>

            <v-form>
                <v-container class="py-0">
                <v-row>
                    <template v-if="type == 1">
                        <v-col cols="12" md="6" v-for="item in data_champ" :key="item.id">

                            <div class="row" v-if="item.label== 'Date'">
                                <span class="text-left" style="color: grey;"> {{item.label}}</span>
                                <el-date-picker v-model="form[item.value]" type="date" placeholder="Choississez le jour"></el-date-picker>
                            </div>
                            <template v-else-if="item.label== 'Montant'">
                                <!-- <v-text-field  :label="item.label" v-model="form[item.value]" disabled/> -->
                            </template>
                            <v-text-field v-else :label="item.label" v-model="form[item.value]" @change='calculMontant' />

                        </v-col>    
                    </template>
                    <template v-else>
                        <v-col cols="12" md="6" v-for="item in data_champ" :key="item.id">
                            <div class="row" v-if="item.type== 'date'">
                                <span class="text-left" style="color: grey;"> {{item.label}}</span>
                                <el-date-picker v-model="form[item.value]" type="date" placeholder="Choississez le jour"></el-date-picker>
                            </div>
                            <v-text-field v-else :label="item.label" v-model="form[item.value]"/>
                        </v-col> 
                    </template>
                   

                    <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0" @click="SaveData()"> Enregistrer </v-btn>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
            </base-material-card>
        </v-col>
        </v-row>
        </v-card-text>
    </v-dialog>
</template>
<script>

    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';

    export default {

        props: ['dialog','data_champ','Modal_title','type','loadConfig','agenceSelect'],
        
        data() {
            return {
                form: {
                    mois : null,
                    semaine: null,
                    date: moment().toDate()
                },
            }
        },

        created(){

        },

        mounted(){
        },

        watch: {
            data_champ : function() {
                this.setChamp();
                this.form['date'] = moment().toDate();
            },

        },

        methods: {
            
            setChamp(){

                this.data_champ.forEach(element => {

                    if (element.value == 'date') {

                        // this.form[element.value] = '';

                    }else {

                        this.form[element.value] = null;
                    }
                    
                });
                this.form['type'] = null;

                console.log(this.form);

            },

            calculMontant(){

                if (this.form['encaissement_vte'] == null) { this.form['encaissement_vte'] =0};
                if (this.form['encaissement_credit_m1'] == null) { this.form['encaissement_credit_m1'] =0};
                if (this.form['retour'] == null) { this.form['retour'] = 0};

                this.form.montant = parseFloat(this.form['encaissement_credit_m1']) + parseFloat(this.form['encaissement_vte']) - parseFloat(this.form['retour']); 

                console.log(this.form['montant']);
            },

            async SaveData() {

                NProgress.start();

                try {


                    if (this.type == 1) {

                        if (this.form.date != null && this.form.montant != null && this.form.montant != '' ) {

                            moment(moment().toDate(), "MM-DD-YYYY").isoWeek()                                                                                                                                                                                                                               
                            
                            this.form.date = moment(new Date(this.form.date)).format('YYYY-MM-DD');
                            this.form.semaine = moment(new Date(this.form.date)).isoWeek();
                            this.form.mois = (moment(new Date(this.form.date)).month())+1;
                            this.form.type = this.type;
                            this.form.agence = this.agenceSelect.value;
                            

                            const response = await axios.post('xperf/saveOperationEtat', this.form);
                            var res = response.data.message;
                            if(response.status == 200) {

                                this.loadConfig(this.agenceSelect);
                                this.dialog = false;

                                this.$notify({
                                    type: 'success',
                                    title: res
                                });
                            }
                            else {
                                this.$notify({
                                    type: 'error',
                                    title: res
                                });
                            }

                        }
                    }
                    else{
                        
                        if (this.form.date != null && this.form.montant != null && this.form.montant != '' ) {

                            moment(moment().toDate(), "MM-DD-YYYY").isoWeek()                                                                                                                                                                                                                               
                            
                            this.form.date = moment(new Date(this.form.date)).format('YYYY-MM-DD');
                            this.form.semaine = moment(new Date(this.form.date)).isoWeek();
                            this.form.mois = (moment(new Date(this.form.date)).month())+1;
                            this.form.type = this.type;
                            this.form.agence = this.agenceSelect.value;
                            
                            const response = await axios.post('xperf/saveOperationEtat', this.form);
                            var res = response.data.message;
                            if(response.status == 200) {

                                this.loadConfig(this.agenceSelect);
                                this.dialog = false;

                                this.$notify({
                                    type: 'success',
                                    title: res
                                });
                            }
                            else {
                                this.$notify({
                                    type: 'error',
                                    title: res
                                });
                            }

                        }
                    }

                    

                } 
                catch (error) {

                    console.log('d:', error.response);

                    this.$notify({
                        type: 'warning',
                        title: error.response.data.message
                    });
                }

                NProgress.done();
            }
        }
        
    }
</script>